import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  AboutUsPage,
  AdminSignupPage,
  AlreadyPurchasedPage,
  BillingPage,
  BlackListPage,
  CallWhisperPage,
  ContactUsPage,
  ControlPanelPage,
  EmailVerificationPage,
  FaxToEmailPage,
  FirstPage,
  ForgetPasswordPage,
  HolidaySettingsPage,
  HuntGroupPage,
  LandLinePage,
  LoginPage,
  MissedCallAlertPage,
  Numbers0300Page,
  Numbers0333Page,
  Numbers0800Page,
  OrderlistPage,
  Payment,
  Plan0102Page,
  Plan0300Page,
  Plan03Page,
  Plan0800Page,
  PricingPage,
  PrivacyPolicyPage,
  ProfilePage,
  PurchasedNumberPage,
  PurchasedNumbersCardsPage,
  RegisterPage,
  ServicesPage,
  StatsCompPage,
  TamarVoipPage,
  TermsAndConditionsPage,
  UserDataPage,
  VirtualSwitchBoardLicencePage,
  VirtualSwitchBoardPage,
  VoicemailPage,
  WhatsappBuisnessPage,
} from "./pages";
import FaqsPage from "./pages/FaqsPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SuccessPage from "./pages/SuccessPage";
import LicenceSuccessPage from "./pages/LicenceSuccessPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<FirstPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/faqs" element={<FaqsPage />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route
            path="/terms-conditions"
            element={<TermsAndConditionsPage />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/payment-success/:sessionId" element={<SuccessPage />} />
          <Route path="/plan0800" element={<Plan0800Page />} />
          <Route path="/plan0300" element={<Plan0300Page />} />
          <Route path="/plan03" element={<Plan03Page />} />
          <Route path="/plan0102" element={<Plan0102Page />} />
          {/* Authentication */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/forget-password" element={<ForgetPasswordPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/verify-email" element={<EmailVerificationPage />} />

          {/* Control Panel */}
          <Route path="/control-panel" element={<ControlPanelPage />} />
          <Route path="/control-panel-invoice" element={<BillingPage />} />
          <Route
            path="/control-panel-purchase"
            element={<PurchasedNumberPage />}
          />
          <Route
            path="/control-panel-purchased"
            element={<AlreadyPurchasedPage />}
          />
          <Route path="/control-panel-01-02" element={<LandLinePage />} />
          <Route path="/control-panel-0800" element={<Numbers0800Page />} />
          <Route path="/control-panel-0300" element={<Numbers0300Page />} />
          <Route path="/control-panel-0333" element={<Numbers0333Page />} />
          <Route path="/control-panel/payment" element={<Payment />} />
          <Route path="/control-panel-profile" element={<ProfilePage />} />
          <Route
            path="/control-panel-purchased-card"
            element={<PurchasedNumbersCardsPage />}
          />
          <Route
            path="/control-panel-fax-to-email"
            element={<FaxToEmailPage />}
          />
          <Route
            path="/control-panel-voice-email"
            element={<VoicemailPage />}
          />
          <Route path="/control-panel-hunt-grp" element={<HuntGroupPage />} />
          <Route
            path="/control-panel-missed-call-alert"
            element={<MissedCallAlertPage />}
          />
          <Route
            path="/control-panel-blacklist"
            element={<BlackListPage />}
          />
          <Route
            path="/control-panel-holiday-settings"
            element={<HolidaySettingsPage />}
          />
          <Route
            path="/control-panel-whatsapp-buisness"
            element={<WhatsappBuisnessPage />}
          />
          <Route
            path="/control-panel-virtual-switchboard"
            element={<VirtualSwitchBoardPage />}
          />
          <Route
            path="/control-panel-virtual-switchboard-licence"
            element={<VirtualSwitchBoardLicencePage />}
          />
          <Route
            path="/success"
            element={<LicenceSuccessPage />}
          />
          <Route
            path="/control-panel-tamar-voip"
            element={<TamarVoipPage />}
          />
          <Route
            path="/control-panel-call-whisper"
            element={<CallWhisperPage />}
          />
          <Route
            path="/control-panel-stats"
            element={<StatsCompPage />}
          />

          {/* Admin Panel */}
          <Route path="/admin-signup" element={<AdminSignupPage />} />
          <Route path="/admin-user-data" element={<UserDataPage />} />
          <Route path="/admin-order-list" element={<OrderlistPage />} />
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </>
  );
}

export default App;
