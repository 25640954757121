import React from "react";
import { Link } from "react-router-dom";

const HeroSection = () => {
  return (
    <>
      {/* <!--hero section Start--> */}
      {/* <!--=================--> */}
      <div class="hero-section align-items-center">
        <div class="container">
          <div class="row align-items-center rs-pt">
            <div class="col-lg-6 col-md-7 col-sm-7">
              <div class= "hero-content-left">
                <div class="hero-title">
                  <h1>A Landline For</h1>
                  <h1>
                    <span> Your Mobile</span>
                  </h1>
                </div>
                <div class="hero-description">
                  <p>
                    Our Virtual Phone Numbers give you a Local (01/02), 0800,
                    0333 or 0300 number that can be sent to your existing
                    landline or mobile phone. FaithLandline Telecom provide over 10 free
                    features to help you manage when you're open, when you're on
                    holiday and send calls to different telephone numbers at
                    different times of the day.
                  </p>
                </div>
                <div class="hero-button">
                  <a href="/pricing" class="hero-button-01">
                    Choose Your Number{" "}
                    <i class="flaticon flaticon-right-arrow"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-5 col-sm-5">
              <div class="hero-single-thumb">
                <img src="assets/images/hero-img.png" alt="hero img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- hero-sectin end-->	 */}

      {/* <!--services-area start--> */}
      <div class="services-area">
        <div class="container">
          <div class="row d-flex align-items-center">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="section-title t-left">
                <div class="section-main-title text-center">
                  <h1>Choose A Telephone Number</h1>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="single-service">
                <div class="single-service-inner">
                  <div class="service-icon-thumb">
                    <img src="assets/images/service-icon1.png" alt="img" />
                  </div>
                  <div class="service-content">
                    <div class="service-title">
                      <h4>0800 Numbers</h4>
                    </div>
                    <div class="service-description">
                      <p>
                        Help increase enquiries by offering your customers a
                        number that's free to call from landlines AND mobiles.
                      </p>
                    </div>
                    <div class="hero-button">
                      <a href="/pricing" class="hero-button-01 w-100">
                        Choose Your Number{" "}
                        <i class="flaticon flaticon-right-arrow"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="single-service">
                <div class="single-service-inner two">
                  <div class="service-icon-thumb">
                    <img src="assets/images/service-icon2.png" alt="img" />
                  </div>
                  <div class="service-content">
                    <div class="service-title">
                      <h4>Local Numbers 01/02</h4>
                    </div>
                    <div class="service-description">
                      <p>
                        Show customers that you are an established local
                        business with our Virtual Landlines services.
                      </p>
                    </div>
                    <div class="hero-button">
                    <a href="/pricing" class="hero-button-01 w-100">
                        Choose Your Number{" "}
                        <i class="flaticon flaticon-right-arrow"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="single-service">
                <div class="single-service-inner three">
                  <div class="service-icon-thumb">
                    <img src="assets/images/service-icon3.png" alt="img" />
                  </div>
                  <div class="service-content">
                    <div class="service-title">
                      <h4>0333 Numbers</h4>
                    </div>
                    <div class="service-description">
                      <p>
                        Give your business a professional, nationwide presence
                        with our 0333 National Telephone Numbers.
                      </p>
                    </div>
                    <div class="hero-button">
                    <a href="/pricing" class="hero-button-01 w-100">
                        Choose Your Number{" "}
                        <i class="flaticon flaticon-right-arrow"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-12">
              <div class="shape-img">
                <img src="assets/images/service-shape.png" alt="img" />
              </div>
              <div class="shape-img2">
                <img src="assets/images/shape3.png" alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- services-area-end--> */}
      {/* <!--feature-area-start--> */}
      <div class="feature-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="section-title t-center">
                <div class="section-sub-title">
                  <h5>
                    Free Features <span></span>
                  </h5>
                </div>
                <div class="section-main-title">
                  <h1>Included With All Numbers</h1>
                </div>
                <div class="section-description">
                  <p>
                    All FaithLandline numbers come with over 10 free features to help
                    your business grow and control when you receive calls.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="single-feature-area">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="single-feature">
                <div class="single-feature-inner d-flex">
                  <div class="feature-icon">
                    <i class="flaticon flaticon-support-3"></i>
                  </div>
                  <div class="feature-content">
                    <div class="feature-title">
                      <h3> Hunt Groups</h3>
                    </div>
                    <div class="feature-description">
                      <p>
                        Route calls to different numbers any time of the
                        <br /> day.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-feature">
                <div class="single-feature-inner d-flex">
                  <div class="feature-icon two">
                    <i class="flaticon flaticon-download"></i>
                  </div>
                  <div class="feature-content">
                    <div class="feature-title">
                      <h3> Blacklist Numbers</h3>
                    </div>
                    <div class="feature-description">
                      <p>
                        Block numbers from calling you and disable withheld
                        numbers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-feature">
                <div class="single-feature-inner d-flex">
                  <div class="feature-icon three">
                    <i class="fas fa-award"></i>
                  </div>
                  <div class="feature-content">
                    <div class="feature-title">
                      <h3> Voicemail</h3>
                    </div>
                    <div class="feature-description">
                      <p>
                        Add a custom voicemail that can be listened to in the
                        app.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-feature">
                <div class="single-feature-inner d-flex">
                  <div class="feature-icon three">
                    <i class="fas fa-award"></i>
                  </div>
                  <div class="feature-content">
                    <div class="feature-title">
                      <h3> Missed Call Alerts</h3>
                    </div>
                    <div class="feature-description">
                      <p>
                        Get push notifications to your phone when you miss a
                        call.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-feature">
                <div class="single-feature-inner d-flex">
                  <div class="feature-icon">
                    <i class="flaticon flaticon-support-3"></i>
                  </div>
                  <div class="feature-content">
                    <div class="feature-title">
                      <h3> Hunt Groups</h3>
                    </div>
                    <div class="feature-description">
                      <p>
                        Route calls to different numbers any time of the
                        <br /> day.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="single-feature">
                <div class="single-feature-inner d-flex">
                  <div class="feature-icon two">
                    <i class="flaticon flaticon-download"></i>
                  </div>
                  <div class="feature-content">
                    <div class="feature-title">
                      <h3> Holiday Settings</h3>
                    </div>
                    <div class="feature-description">
                      <p>
                        Send calls to different numbers or voicemail when on
                        holiday.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--feature-area-end--> */}

      {/* <!--services-area start--> */}
      <div class="">
        <div class="container">
          <div class="row d-flex align-items-center">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="section-title t-center">
                <div class="section-main-title text-center">
                  <div class="section-sub-title">
                    <h5>
                      Top Features <span></span>
                    </h5>
                  </div>
                  <h1>Premium Features</h1>
                  <p class="mt-3">
                    Want to go the extra mile? Our Premium Features take your
                    business further.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="single-service">
                <div class="single-service-inner">
                  <div class="service-icon-thumb">
                    <img src="assets/images/service-icon1.png" alt="img" />
                  </div>
                  <div class="service-content">
                    <div class="service-title">
                      <h4> Virtual Switchboard</h4>
                    </div>
                    <div class="service-description">
                      <p>
                        Press one for sales, two for support or three for
                        accounts.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="single-service">
                <div class="single-service-inner two">
                  <div class="service-icon-thumb">
                    <img src="assets/images/service-icon2.png" alt="img" />
                  </div>
                  <div class="service-content">
                    <div class="service-title">
                      <h4> Outbound Calling</h4>
                    </div>
                    <div class="service-description">
                      <p>
                        Make outbound calls using your virtual telephone number.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="single-service">
                <div class="single-service-inner three">
                  <div class="service-icon-thumb">
                    <img src="assets/images/service-icon3.png" alt="img" />
                  </div>
                  <div class="service-content">
                    <div class="service-title">
                      <h4> Call Whisper</h4>
                    </div>
                    <div class="service-description">
                      <p>
                        Identify business calls to your mobile before you
                        answer.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="shape-img">
                <img src="assets/images/service-shape.png" alt="img" />
              </div>
              <div class="shape-img2">
                <img src="assets/images/shape3.png" alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- services-area-end--> */}

      {/* <!--blog-area-start--> */}
      <div class="blog-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="section-title t-center">
                <div class="section-sub-title">
                  <h5>
                    Our Blog <span></span>
                  </h5>
                </div>
                <div class="section-main-title">
                  <h1>The Latest From Our Blog</h1>
                </div>
                {/* <!-- <div class="section-description">
								<p>Sit amet consectetur adipiscing elite sed eiusmod tempor incididunt ut labore dolore pendisse ultrices gravidaisus commodo viva aliqua quis</p>
							</div> --> */}
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <div class="blog-slide owl-carousel">
              <div class="col-lg-12">
                <div class="single-blog">
                  <div class="blog-thumb upper">
                    <a href="#">
                      <img src="assets/images/blog-1.jpg" alt="blog img" />
                    </a>
                  </div>
                  <div class="blog-content upper">
                    <div class="blog-title">
                      <a href="#">
                        <h3>How do I set up a business phone number?</h3>
                      </a>
                    </div>
                    <div class="zomia-learn-button upper mt-3">
                      <a href="#">Read More</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="single-blog">
                  <div class="blog-thumb upper">
                    <a href="#">
                      <img src="assets/images/blog-2.jpg" alt="blog img" />
                    </a>
                  </div>
                  <div class="blog-content upper">
                    <div class="blog-title">
                      <a href="#">
                        <h3>Top 10 working from home ideas</h3>
                      </a>
                    </div>
                    <div class="zomia-learn-button upper mt-3">
                      <a href="#">Read More</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="single-blog">
                  <div class="blog-thumb upper">
                    <a href="#">
                      <img src="assets/images/blog-3.jpg" alt="blog img" />
                    </a>
                  </div>
                  <div class="blog-content upper">
                    <div class="blog-title">
                      <a href="#">
                        <h3>Christmas & New Year opening hours</h3>
                      </a>
                    </div>
                    <div class="zomia-learn-button upper mt-3">
                      <a href="#">Read More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--blog-area-end--> */}
    </>
  );
};

export default HeroSection;
