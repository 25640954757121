import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const HeroSection = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      toast.error("Please enter your email.");
      return;
    }

    try {
      // Call your API to trigger the forgot password email
      await axios.post("http://back.faithlandline.co.uk/api/auth/forgot-password", { email });

      toast.success("Password reset link sent to your email.");
    } catch (error) {
      toast.error("Error sending password reset email.");
    }
  };

  return (
    <div>
      <div className="breatcome-area align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="breatcome-content">
                <div className="breatcome-title">
                  <h1>Forgot Password</h1>
                </div>
                <div className="breatcome-text">
                  <ul>
                    <li>
                      <a href="/">
                        Home <i className="fa fa-angle-right"></i>
                      </a>
                    </li>
                    <li>
                      <span>Forgot Password</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-area mt-12">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="contact-section">
                <div className="contact-title">
                  <h2>Forgot Password</h2>
                </div>
                <div className="control-contact-form">
                  <form onSubmit={handleSubmit}>
                    <div className="input-box mb-4">
                      <label>Email</label>
                      <input
                        type="email"
                        placeholder="E-mail"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="input-button">
                      <button type="submit">Submit</button>
                    </div>
                  </form>
                  <div className="col-md-8">
                    <p>
                      Don’t Have Account? <a href="/register">Register</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
