import React, { useState } from "react";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import { Link } from "react-router-dom";

const FaxToEmail = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = (open) => {
    setSidebarOpen(open);
  };
  return (
    <div>
       <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
   
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
      <Navbar toggleSidebar={toggleSidebar} />
        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-lg-12 col-md-10 mx-auto">
              {/* Card Design */}
              <div className="card shadow-lg border-0 rounded-lg">
                <div className="card-body">
                  {/* Heading */}
                  <h2 className="card-title text-center mb-4">
                    Fax-To-Email
                  </h2>
                  
                  {/* Horizontal Line */}
                  <hr className="my-4"/>

                  {/* First line */}
                  <p className="text-muted text-center">
                    To view faxes received or to make changes to one of your fax numbers, select the number from the dropdown and select View/Edit.
                  </p>

                  {/* Second line */}
                  <p className="text-muted text-center mb-4">
                    The Fax to email service can be added to an existing number, via the <Link>Number administration section.</Link>
                  </p>

                  {/* Third Line */}
                  <div className="alert alert-warning text-center">
                    You do not currently have any fax-to-email services.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default FaxToEmail;
