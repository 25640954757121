import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Card, Form, Table } from "react-bootstrap";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import { Link } from "react-router-dom";

const VirtualSwitchBoard = () => {
  const [switchboards, setSwitchboards] = useState([]);
  const [selectedSwitchboardId, setSelectedSwitchboardId] = useState(null); // State to track selected switchboard ID
  const [showCreateCard, setShowCreateCard] = useState(false);
  const [showEditCard, setShowEditCard] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [description, setDescription] = useState("");
  const userId = localStorage.getItem("user_id");
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = (open) => {
    setSidebarOpen(open);
  };

  useEffect(() => {
    // Fetch data from the API and set switchboards state
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `http://back.faithlandline.co.uk/api/switchboard/user/${userId}` // Update with your API endpoint
        );
        setSwitchboards(response.data.switchboards);
      } catch (error) {
        console.error("Error fetching switchboards:", error);
      }
    };

    fetchData();
  }, [userId]);

  const handleCreateClick = () => {
    setShowCreateCard(true);
    setShowEditCard(false);
  };

  const handleEditClick = () => {
    setShowCreateCard(false);
    setShowEditCard(true);
  };

  const handleDropdownSelect = (switchboardId) => {
    console.log(switchboardId);

    setSelectedSwitchboardId(switchboardId);
    setShowDropdown(false);
    // Optionally show the edit card or perform other actions
    setShowEditCard(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Make API request to create a new virtual switchboard
      const response = await axios.post(
        `http://back.faithlandline.co.uk/api/switchboard/new/${userId}`,
        { description }
      );
      console.log("Switchboard created:", response.data);
      window.location.reload();
      // Clear the form and hide the create card
      setDescription("");
      setShowCreateCard(false);
      // Optionally refresh the switchboards list
    } catch (error) {
      console.error("Error creating switchboard:", error);
    }
  };

  const [voicemailBoxes, setVoicemailBoxes] = useState([]);
  const [selectedVoicemailBox, setSelectedVoicemailBox] = useState("");

  // Fetch switchboards data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `http://back.faithlandline.co.uk/api/switchboard/user/${userId}`
        );
        setSwitchboards(response.data.switchboards);
      } catch (error) {
        console.error("Error fetching switchboards:", error);
      }
    };
    fetchData();
  }, [userId]);

  // Fetch voicemail boxes from API
  useEffect(() => {
    const fetchVoicemailBoxes = async () => {
      try {
        const response = await axios.get(
          `http://back.faithlandline.co.uk/api/voicemails/user/${userId}`
        );
        setVoicemailBoxes(response.data);
      } catch (error) {
        console.error("Error fetching voicemail boxes:", error);
      }
    };
    fetchVoicemailBoxes();
  }, [userId]);

  const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
  const weekend = ["Saturday", "Sunday"];
  const [checkedDays, setCheckedDays] = useState({
    Monday: true,
    Tuesday: true,
    Wednesday: true,
    Thursday: true,
    Friday: true,
    Saturday: false,
    Sunday: false,
  });

  const handleCheckboxChange = (day) => {
    setCheckedDays((prevState) => ({
      ...prevState,
      [day]: !prevState[day],
    }));
  };

  return (
    <div>
       <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
   
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
      <Navbar toggleSidebar={toggleSidebar} />
        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-lg-8 col-md-12 mx-auto">
              <Card>
                <Card.Body>
                  <Card.Title>
                    Select an existing virtual switchboard or create a new one
                  </Card.Title>
                  <div className="dropdown">
                    <Button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      onClick={() => setShowDropdown(!showDropdown)}
                    >
                      Select Switchboard
                    </Button>
                    {showDropdown && (
                      <ul className="dropdown-menu show">
                        {switchboards.map((switchboard) => (
                          <li key={switchboard.id}>
                            <a
                              className="dropdown-item"
                              onClick={() =>
                                handleDropdownSelect(switchboard.switchboardId)
                              }
                            >
                              {switchboard.description}
                            </a>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  <div className="mt-3">
                    <Button variant="primary" onClick={handleEditClick}>
                      View/Edit
                    </Button>
                    <Button
                      variant="secondary"
                      className="ms-2"
                      onClick={handleCreateClick}
                    >
                      Create New
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </div>

            <div className="col-md-4">
              <Card>
                <Card.Body>
                  <Card.Title>Virtual Switchboard Licences</Card.Title>
                  <p>Licences: 0, Available: 0</p>
                  <Link className="btn btn-primary" role="button" to="/control-panel-virtual-switchboard-licence">Purchase Licence</Link>
                </Card.Body>
              </Card>
            </div>

            {showCreateCard && (
              <div className="col-md-12 mt-4">
                <Card>
                  <Card.Body>
                    <Card.Title>Create a New Virtual Switchboard</Card.Title>
                    <Form onSubmit={handleSubmit}>
                      <Form.Group controlId="formDescription">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Name for this virtual Switch board"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </Form.Group>
                      <Button variant="primary" type="submit" className="mt-2">
                        Create
                      </Button>
                    </Form>
                  </Card.Body>
                </Card>
              </div>
            )}

            {showEditCard && selectedSwitchboardId && (
              <div className="col-md-12 mt-4">
                <div className="container-fluid py-4">
                  <div className="row">
                    {/* Left Column - Open Hours */}
                    <div className="col-lg-8 col-md-12 mx-auto">
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>Open Hours</Card.Title>
                          <p>
                            During your open hours, callers are greeted with
                            your Welcome menu and calls are routed as per your
                            During open hours configuration.
                          </p>
                          <p>
                            When you are closed, callers are sent straight to
                            your Closed voicemail.
                          </p>
                          <h4 className="text-center fw-bold">
                            Opening Hours (24hr)
                          </h4>
                          <Table className="table table-borderless">
                            <thead>
                              <tr>
                                <th>Day</th>
                                <th>Closed</th>
                                <th>From</th>
                                <th>To</th>
                              </tr>
                            </thead>
                            <tbody>
                              {daysOfWeek.map((day) => (
                                <tr key={day}>
                                  <td>{day}</td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={checkedDays[day]}
                                      onChange={() => handleCheckboxChange(day)}
                                    />
                                  </td>
                                  <td>
                                    {checkedDays[day] ? (
                                      "Closed all day"
                                    ) : (
                                      <Form.Control type="time" />
                                    )}
                                  </td>
                                  <td>
                                    {checkedDays[day] ? (
                                      "Closed all day"
                                    ) : (
                                      <Form.Control type="time" />
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>

                          <h4 className="text-center fw-bold">
                            Weekend Hours (24hr)
                          </h4>
                          <Table className="table table-borderless">
                            <thead>
                              <tr>
                                <th>Day</th>
                                <th>Closed</th>
                                <th>From</th>
                                <th>To</th>
                              </tr>
                            </thead>
                            <tbody>
                              {weekend.map((day) => (
                                <tr key={day}>
                                  <td>{day}</td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={checkedDays[day]}
                                      onChange={() => handleCheckboxChange(day)}
                                    />
                                  </td>
                                  <td>
                                    {checkedDays[day] ? (
                                      "Closed all day"
                                    ) : (
                                      <Form.Control type="time" />
                                    )}
                                  </td>
                                  <td>
                                    {checkedDays[day] ? (
                                      "Closed all day"
                                    ) : (
                                      <Form.Control type="time" />
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          <Button
                            className="mt-3 d-block mx-auto"
                            variant="primary"
                          >
                            Save Changes
                          </Button>
                        </Card.Body>
                      </Card>
                    </div>

                    {/* Right Column - Switchboard Info */}
                    <div className="col-md-4">
                      <Card className="mb-4">
                        <Card.Body>
                          <Card.Title>Description</Card.Title>
                          <p>Name or reference for this virtual switchboard</p>
                          <Form.Control
                            type="text"
                            placeholder={
                              selectedSwitchboardId
                                ? switchboards.find(
                                    (s) =>
                                      s.switchboardId === selectedSwitchboardId
                                  )?.description || ""
                                : ""
                            }
                            readOnly
                          />
                        </Card.Body>
                      </Card>

                      {/* Closed Voicemail Card */}
                      <Card>
                        <Card.Body>
                          <Card.Title>Closed Voicemail</Card.Title>
                          <p>
                            When we are closed, send calls to the following
                            voicemail box:
                          </p>
                          <Form.Select
                            value={selectedVoicemailBox}
                            onChange={(e) =>
                              setSelectedVoicemailBox(e.target.value)
                            }
                          >
                            <option value="">Select Voicemail Box</option>
                            {voicemailBoxes.map((box) => (
                              <option
                                key={box.voicemailId}
                                value={box.voicemailId}
                              >
                                {box.description}
                              </option>
                            ))}
                          </Form.Select>
                        </Card.Body>
                      </Card>
                    </div>

                    {/* Create New Switchboard */}
                    {showCreateCard && (
                      <div className="col-md-12 mt-4">
                        <Card>
                          <Card.Body>
                            <Card.Title>
                              Create a New Virtual Switchboard
                            </Card.Title>
                            <Form onSubmit={handleSubmit}>
                              <Form.Group controlId="formDescription">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Name for this virtual Switchboard"
                                  value={description}
                                  onChange={(e) =>
                                    setDescription(e.target.value)
                                  }
                                />
                              </Form.Group>
                              <Button
                                variant="primary"
                                type="submit"
                                className="mt-2"
                              >
                                Create
                              </Button>
                            </Form>
                          </Card.Body>
                        </Card>
                      </div>
                    )}

                    {/* Edit Switchboard */}
                    {showEditCard && selectedSwitchboardId && (
                      <div className="col-md-12 mt-4">
                        <Card>
                          <Card.Body>
                            <Card.Title>
                              View/Edit Virtual Switchboard
                            </Card.Title>
                            <p>
                              Selected Switchboard ID: {selectedSwitchboardId}
                            </p>
                            {/* Display and handle further actions based on selectedSwitchboardId */}
                          </Card.Body>
                        </Card>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default VirtualSwitchBoard;
