import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const LicenceSuccessPage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const location = useLocation();
  const navigate = useNavigate(); // Use navigate for redirection

  // Function to get session ID from the URL
  const getSessionIdFromURL = () => {
    const params = new URLSearchParams(location.search);
    return params.get('session_id');
  };

  useEffect(() => {
    const sessionId = getSessionIdFromURL();

    // If session_id exists in the URL, verify it with Stripe
    if (sessionId) {
      verifySession(sessionId);
    } else {
      setError('No session ID found');
      setLoading(false);
    }
  }, [location]);

  // Function to verify the session with Stripe and update the license
  const verifySession = async (sessionId) => {
    try {
      // Fetch userId from localStorage
      const userId = localStorage.getItem('user_id');

      // If no userId is found in localStorage, handle the error
      if (!userId) {
        throw new Error('User ID not found in local storage.');
      }

      // Fetch session details from Stripe
      const { data: sessionData } = await axios.get(`http://back.faithlandline.co.uk/api/verify-stripe-session?session_id=${sessionId}`);

      // If session is valid, post data to backend to update Switchboard License
      await axios.post('http://back.faithlandline.co.uk/api/update-switchboard-license', {
        userId, // Send the userId from localStorage
        sessionId,
      });

      setSuccess(true);

      // Redirect to homepage after 3 seconds
      setTimeout(() => {
        navigate('/'); // Redirect to the homepage
      }, 3000);

    } catch (error) {
      setError(error.message || 'Failed to verify payment session.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (success) {
    return (
      <div>
        Payment was successful! Your license has been updated.
        <br />
        Redirecting to the homepage...
      </div>
    );
  }

  return <div>Something went wrong. Please try again later.</div>;
};

export default LicenceSuccessPage;
