import React from 'react'
import { Link } from 'react-router-dom'

const HeroSection = () => {
  return (
    <div>
        
		{/* <!--hero section Start--> */}
		{/* <!--=================--> */}
		<div class="breatcome-area align-items-center">
			<div class="container">
				<div class="row">
					<div class="col-lg-12 col-md-12 col-sm-12">
						<div class="breatcome-content">
							<div class="breatcome-title">
								<h1>Contact</h1>
							</div>
							<div class="breatcome-text">
								<ul>
									<li>                      <a  to="/">
                                    Home <i class="fa fa-angle-right"></i></a></li>
									<li><span>Contact</span></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
        {/* <!-- breatcome-area end-->	 */}

		{/* <!--map-area-start--> */}
		<div class="map-area">
			<div class="row">
				<div class="col-lg-12">
					<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d158858.182370726!2d-0.10159865000000001!3d51.52864165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a00baf21de75%3A0x52963a5addd52a99!2sLondon%2C%20UK!5e0!3m2!1sen!2sbd!4v1656917741388!5m2!1sen!2sbd" width="400" height="300" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
				</div>
			</div>
		</div>
        {/* <!--map-area-end--> */}

		{/* <!--contact-area Start--> */}
		<div class="contact-area">
			<div class="container-fluid">
				<div class="row">
					<div class="col-lg-12">
						<div class="contact-section">
							<div class="row">
								<div class="col-lg-5 col-md-12">
									<div class="contact-address">
										<div class="single-contact">
											<div class="contact-content d-flex">
												<div class="contact-icon">
													<i class="flaticon flaticon-placeholder"></i>
												</div>
												<div class="contact-inner">
													<div class="title">
														<h2>Location</h2>
													</div>
													<div class="description">
														<p>55 Main Street, New York<br/>576d University St, UK</p>
													</div>
												</div>
											</div>
										</div>
										<div class="single-contact">
											<div class="contact-content d-flex">
												<div class="contact-icon">
													<i class="flaticon flaticon-email"></i>
												</div>
												<div class="contact-inner">
													<div class="title">
														<h2>Email Us</h2>
													</div>
													<div class="description">
														<p>example@gmail.com.<br/>example@gmail.com.</p>
													</div>
												</div>
											</div>
										</div>
										<div class="single-contact">
											<div class="contact-content d-flex">
												<div class="contact-icon">
													<i class="flaticon flaticon-phone-call"></i>
												</div>
												<div class="contact-inner">
													<div class="title">
														<h2>Hotline</h2>
													</div>
													<div class="description">
														<p>+012 (345) 678, <br/>+880185022</p>
													</div>
												</div>
											</div>
										</div>
										<div class="contact-social-icon">
											<a href="#"><i aria-hidden="true" class="fab fa-facebook-f"></i></a>
											<a href="#"><i aria-hidden="true" class="fab fa-twitter"></i></a>
											<a href="#"><i aria-hidden="true" class="fab fa-youtube"></i></a>
											<a href="#"><i class="flaticon flaticon-pinterest-1"></i></a> 
										</div>
									</div>
								</div>
								<div class="col-lg-7 col-md-12"> 
									<div class="contact-title">
										<h2>Contact Us</h2>
									</div>
									<div class="control-contact-form">
										<form action="" method="POST">
											<div class="row">
												<div class="col-lg-6 col-md-6">
													<div class="input-box">
														<input type="text" name="Name" placeholder="Fast Name" required/>
													</div>
												</div>
												<div class="col-lg-6 col-md-6">
													<div class="input-box">
														<input type="text" name="Name" placeholder="Last Name" required/>
													</div>
												</div>
												
												<div class="col-lg-6 col-md-6">
													<div class="input-box">
														<input type="text" name="Your Email" placeholder="E-mail"/>
													</div>
												</div>
												<div class="col-lg-6 col-md-6">
													<div class="input-box">
														<input type="text" name="Your Phone" placeholder="Phone No."/>
													</div>
												</div>
												<div class="col-lg-12 col-md-12">
													<div class="input-box">
														<textarea name="message" id="message" cols="30" rows="10" placeholder="Your Message..."></textarea>
													</div>
													<div class="input-button">
														<button type="submit">Sent Message</button>
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
        {/* <!-- contact-area end--> */}
    </div>
  )
}

export default HeroSection