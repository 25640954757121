import React, { useEffect, useState } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import {
  FaUsers,
  FaPhoneSlash,
  FaCalendarAlt,
  FaMobileAlt,
  FaEnvelopeOpenText,
} from "react-icons/fa";
import { AiOutlineAppstore } from "react-icons/ai";
import {
  MdBusiness,
  MdRecordVoiceOver,
  MdOutlineSettingsVoice,
} from "react-icons/md";
import { IoMdRecording } from "react-icons/io";
import { AiFillDashboard } from "react-icons/ai";
import { MdSwitchAccount } from "react-icons/md";
import { FaFax, FaVoicemail, FaWhatsappSquare } from "react-icons/fa";
import { IoCallSharp } from "react-icons/io5";
import { RiVoiceRecognitionFill, RiVoiceprintFill } from "react-icons/ri";

const ControlPanel = () => {
  const [totalNumbers, setTotalNumbers] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isVerified, setIsVerified] = useState(false);

  const navigate = useNavigate();
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = (open) => {
    setSidebarOpen(open);
  };

  useEffect(() => {
    const user_id = localStorage.getItem("user_id");

    if (!user_id) {
      navigate("/");
      return;
    }

    const fetchUserData = async () => {
      try {
        // Fetch numbers data
        const numbersResponse = await axios.get(
          `http://back.faithlandline.co.uk/api/numbers/user/${user_id}`
        );
        const numbers = numbersResponse.data;

        // Calculate total number and sum of prices
        const total = numbers.length;
        const priceSum = numbers.reduce((acc, item) => acc + item.price, 0);

        setTotalNumbers(total);
        setTotalPrice(priceSum);

        // Fetch user data
        const userResponse = await axios.get(
          `http://back.faithlandline.co.uk/api/auth/user/${user_id}`
        );
        const user = userResponse.data.user;

        setIsVerified(user.isVerified);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchUserData();
  }, [navigate]);

  return (
    <>
      <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
   
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
      <Navbar toggleSidebar={toggleSidebar} />
        <div className="container-fluid py-4">
          <div className="row">
            {/* Existing Cards */}
            <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
              <div className="card">
                <div className="card-header p-3 pt-2">
                  <div className="icon icon-lg icon-shape bg-gradient-dark shadow-dark text-center border-radius-xl mt-n4 position-absolute">
                    <i className="material-icons opacity-10">weekend</i>
                  </div>
                  <div className="text-end pt-1">
                    <p className="text-sm mb-0 text-capitalize">
                      Total Numbers
                    </p>
                    <h4 className="mb-0">{totalNumbers}</h4>
                  </div>
                </div>
                <hr className="dark horizontal my-0" />
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
              <div className="card">
                <div className="card-header p-3 pt-2">
                  <div className="icon icon-lg icon-shape bg-gradient-primary shadow-primary text-center border-radius-xl mt-n4 position-absolute">
                    <i className="material-icons opacity-10">person</i>
                  </div>
                  <div className="text-end pt-1">
                    <p className="text-sm mb-0 text-capitalize">
                      Total Purchased
                    </p>
                    <h4 className="mb-0">{totalPrice}</h4>
                  </div>
                </div>
                <hr className="dark horizontal my-0" />
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
              <div className="card">
                <div className="card-header p-3 pt-2">
                  <div className="icon icon-lg icon-shape bg-gradient-dark shadow-primary text-center border-radius-xl mt-n4 position-absolute">
                    <i className="material-icons opacity-10">person</i>
                  </div>
                  <div className="text-end pt-1">
                    <p className="text-sm mb-0 text-capitalize">Total Paid</p>
                    <h4 className="mb-0">{totalPrice}</h4>
                  </div>
                </div>
                <hr className="dark horizontal my-0" />
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div className="card">
                <div className="card-header p-3 pt-2">
                  <div className="icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                    <i className="material-icons opacity-10">verified_user</i>
                  </div>
                  <div className="text-end pt-1">
                    <p className="text-sm mb-0 text-capitalize">
                      Email Verification
                    </p>
                    <h4 className="mb-0">
                      {isVerified ? "Verified" : "Not Verified"}
                    </h4>
                  </div>
                </div>
                <hr className="dark horizontal my-0" />
              </div>
            </div>
          </div>

          {/* Included Features Section */}
          <div className="mt-5">
            <h3 className="text-center">Included Features</h3>
            <p className="text-center">
              This section is where you configure the various advanced services
              that are available to you on your portal.
            </p>
            <div className="row">
              <div className="col-md-3">
                <Link to="/control-panel-fax-to-email">
                  <div className="card text-center p-1">
                    <span className="d-flex justify-content-center">
                      <FaFax
                        size={40}
                        className="my-2"
                        style={{ color: "#ec407a" }}
                      />
                    </span>
                    <h5 className="card-title">Fax-To-Email</h5>
                  </div>
                </Link>
              </div>
              <div className="col-md-3">
                <Link to="/control-panel-voice-email">
                  <div className="card text-center">
                    <span className="d-flex justify-content-center">
                      <FaVoicemail
                        size={40}
                        className="my-2"
                        style={{ color: "#ec407a" }}
                      />
                    </span>
                    <h5 className="card-title">Voicemail</h5>
                  </div>
                </Link>
              </div>
              <div className="col-md-3">
                <Link to="/control-panel-hunt-grp">
                  <div className="card text-center">
                    <span className="d-flex justify-content-center">
                      <FaUsers
                        size={40}
                        className="my-2"
                        style={{ color: "#ec407a" }}
                      />
                    </span>
                    <h5 className="card-title">Hunt Groups</h5>
                  </div>
                </Link>
              </div>
              <div className="col-md-3">
                <Link to="/control-panel-missed-call-alert">
                  <div className="card text-center">
                    <span className="d-flex justify-content-center">
                      <FaPhoneSlash
                        size={40}
                        className="my-2"
                        style={{ color: "#ec407a" }}
                      />
                    </span>
                    <h5 className="card-title">Missed Call Alerts</h5>
                  </div>
                </Link>
              </div>
              
              <div className="col-md-3">
                <Link to="/control-panel-blacklist">
                  <div className="card text-center mt-2">
                    <span className="d-flex justify-content-center">
                      <FaCalendarAlt
                        size={40}
                        className="my-2"
                        style={{ color: "#ec407a" }}
                      />
                    </span>
                    <h5 className="card-title">BlackList</h5>
                  </div>
                </Link>
              </div>
              
              <div className="col-md-3">
              <Link to="/control-panel-holiday-settings">
                  <div className="card text-center mt-2">
                    <span className="d-flex justify-content-center">
                      <FaCalendarAlt
                        size={40}
                        className="my-2"
                        style={{ color: "#ec407a" }}
                      />
                    </span>
                    <h5 className="card-title">Holiday Settings</h5>
                  </div>
                </Link>
              </div>
              
              <div className="col-md-3">
                <Link to="/control-panel-whatsapp-buisness">
                  <div className="card text-center mt-2">
                    <span className="d-flex justify-content-center">
                      <FaMobileAlt
                        size={40}
                        className="my-2"
                        style={{ color: "#ec407a" }}
                      />
                    </span>
                    <h5 className="card-title">WhatsApp Business</h5>
                  </div>
                </Link>
              </div>
              <div className="col-md-3">
                <div className="card text-center mt-2">
                  <span className="d-flex justify-content-center">
                    <AiOutlineAppstore
                      size={40}
                      className="my-2"
                      style={{ color: "#ec407a" }}
                    />
                  </span>
                  <h5 className="card-title">Control Panel App</h5>
                </div>
              </div>
            </div>
          </div>

          {/* Premium Features Section */}
          <div className="mt-5">
            <h3 className="text-center">Premium Features</h3>
            <p className="text-center">
              Premium features to help your business stand out.
            </p>
            <div className="row">
              <div className="col-md-3">
                <Link to="/control-panel-virtual-switchboard">
                <div className="card text-center">
                  <span className="d-flex justify-content-center">
                    <MdSwitchAccount
                      size={40}
                      className="my-2"
                      style={{ color: "#ec407a" }}
                    />
                  </span>
                  <h5 className="card-title">Virtual Switchboard</h5>
                </div>
                </Link>
              </div>
              <div className="col-md-3">
                <Link to="/control-panel-tamar-voip">
                <div className="card text-center">
                  <span className="d-flex justify-content-center">
                    <MdBusiness
                      size={40}
                      className="my-2"
                      style={{ color: "#ec407a" }}
                    />
                  </span>
                  <h5 className="card-title">Tamar VoIP</h5>
                </div>
                </Link>
              </div>
              <div className="col-md-3">
                <div className="card text-center">
                  <span className="d-flex justify-content-center">
                    <IoMdRecording
                      size={40}
                      className="my-2"
                      style={{ color: "#ec407a" }}
                    />
                  </span>
                  <h5 className="card-title">Call Recording</h5>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card text-center">
                  <span className="d-flex justify-content-center">
                    <MdRecordVoiceOver
                      size={40}
                      className="my-2"
                      style={{ color: "#ec407a" }}
                    />
                  </span>
                  <h5 className="card-title">Professional Voiceovers</h5>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card text-center">
                  <span className="d-flex justify-content-center">
                    <RiVoiceRecognitionFill
                      size={40}
                      className="my-2"
                      style={{ color: "#ec407a" }}
                    />
                  </span>
                  <h5 className="card-title">Speech Recognition</h5>
                </div>
              </div>
              
              <div className="col-md-3">
                <Link to="/control-panel-call-whisper">
                <div className="card text-center">
                  <span className="d-flex justify-content-center">
                    <RiVoiceprintFill
                      size={40}
                      className="my-2"
                      style={{ color: "#ec407a" }}
                    />
                  </span>
                  <h5 className="card-title">Call Whisper</h5>
                </div>
                </Link>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </main>
    </>
  );
};

export default ControlPanel;
