import React, { useState } from "react";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import { Link } from "react-router-dom";

const PurchasedNumbersCards = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = (open) => {
    setSidebarOpen(open);
  };

  return (
    <div>
      <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
   

      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
      <Navbar toggleSidebar={toggleSidebar} />
        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-md-6">
              <Link to="/control-panel-01-02">
                <div className="card bg-gradient-primary text-white mb-4 shadow-lg cursor-pointer">
                  <div className="card-body">
                    <h4 className="card-title font-weight-bold text-white">
                      01, 02 Local Numbers
                    </h4>
                    <p className="card-text">Mobile landline numbers</p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-6">
              <Link to="/control-panel-0800">
                <div className="card bg-gradient-info text-white mb-4 shadow-lg cursor-pointer">
                  <div className="card-body">
                    <h4 className="card-title font-weight-bold text-white">
                      0800 Numbers
                    </h4>
                    <p className="card-text">
                      Free to call from Landlines and Mobiles
                    </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-6">
              <Link to="/control-panel-0300">
                <div className="card bg-gradient-success text-white mb-4 shadow-lg cursor-pointer">
                  <div className="card-body">
                    <h4 className="card-title font-weight-bold text-white">
                      0333 Numbers
                    </h4>
                    <p className="card-text">Numbers for nationwide presence</p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-6">
              <Link to="/control-panel-0333">
                <div className="card bg-gradient-warning text-white mb-4 shadow-lg cursor-pointer">
                  <div className="card-body">
                    <h4 className="card-title font-weight-bold text-white">
                      0300 Numbers
                    </h4>
                    <p className="card-text">For charities and public bodies</p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-6">
              {/* <div className="card bg-gradient-danger text-white mb-4 shadow-lg cursor-pointer">
                <div className="card-body">
                  <h4 className="card-title font-weight-bold text-white">
                    VoIP System
                  </h4>
                  <p className="card-text">Complete Business Phone solution</p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default PurchasedNumbersCards;
