import React, { useState } from "react";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

// Stripe publishable key
const stripePromise = loadStripe(
  "pk_live_51NIuvRELKdOM3HNefMdJ7x2SG1fQtz7NXJElWU9dHFaulGE9Smr4cDNH4r1JOF1hd0xllUOtZJRBmvEN9M0x5QjF00RKHdARuz"
);

const VirtualSwitchBoardLicence = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const userId = localStorage.getItem("user_id");
  const [price, setPrice] = useState(578); // £5.78 in pence
  const [productName, setProductName] = useState("Virtual Switchboard");
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = (open) => {
    setSidebarOpen(open);
  };

  const handlePurchase = async () => {
    try {
      const { data } = await axios.post(
        "http://back.faithlandline.co.uk/api/create-stripe-session",
        {
          productName,
          unitAmount: price,
          userId,
        }
      );

      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId: data.sessionId,
      });
      if (error) {
        console.error("Error during checkout:", error.message);
      }
    } catch (err) {
      console.error("Error creating session:", err.message);
    }
  };

  return (
    <div>
       <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
   
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
      <Navbar toggleSidebar={toggleSidebar} />
        <div className="container pt-4">
          <div className="row p-2 p-md-4 pt-0 pt-md-5 mb-5">
            <div className="col-lg-8 pt-4">
              <h3 className="header-title">Virtual Switchboard Setup</h3>

              <p className="lead pt-3 pt-md-3">
                You can add your very own, customised Virtual Switchboard to any
                of yourFaithLandline Numbers. Simply select{" "}
                <span style={{ fontWeight: "400" }}>Add To Your Account</span>{" "}
                and we'll add a licence to your account so you can get set up
                straight away.
              </p>
              <p className="lead">
                Virtual Switchboard costs £5.78+VAT per month with no setup
                costs!
              </p>

              <div className="pt-4 text-start text-md-center text-lg-start">
                <p
                  className="btn btn-secondary mb-0"
                  style={{
                    fontSize: "18px",
                    padding: "10px 25px",
                    marginRight: "5px",
                  }}
                >
                  0 Licences Used, 2 Available
                </p>
                <button
                  onClick={() => setModalOpen(true)}
                  className="btn btn-primary"
                  style={{
                    background: "#382e62 !important",
                    fontSize: "18px",
                    padding: "10px 25px",
                    marginRight: "5px",
                  }}
                >
                  Add To Your Account
                </button>
                <a
                  href="/phonedivert/vsb?run=true"
                  className="btn btn-primary"
                  style={{
                    background: "#382e62 !important",
                    fontSize: "18px",
                    padding: "10px 25px",
                  }}
                >
                  Run Setup
                </a>
              </div>
            </div>
            <div className="d-none d-lg-block col-lg-4 text-center mb-5">
              <img
                src="https://www.tamartelecommunications.co.uk/wp-content/themes/tamar/img/vsb-intro.png"
                alt="Virtual Phone Numbers"
                className="mx-auto img-fluid w-75"
                style={{ filter: "invert(0)" }}
              />
            </div>
          </div>
        </div>

        {modalOpen && (
          <div
            className="modal fade show"
            id="purchaseModal"
            tabindex="-1"
            aria-labelledby="purchaseModal"
            aria-modal="true"
            role="dialog"
            style={{
              display: "block",
              zIndex: "99999999",
              background: "#0000007a",
            }}
          >
            <div className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="purchaseModal">
                    Virtual Switchboard
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setModalOpen(false)}
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <p className="lead py-2 py-md-0">
                    This will add a monthly charge of £5.78 to your bill. Do you
                    wish to proceed?
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    onClick={handlePurchase}
                    className="btn btn-secondary"
                    style={{
                      background: "#382e62 !important",
                      color: "#FFF !important",
                      fontSize: "18px",
                      padding: "10px 25px",
                    }}
                  >
                    Add Virtual Switchboard
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default VirtualSwitchBoardLicence;
