import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, Link, useLocation } from "react-router-dom"; // For navigation and location

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const location = useLocation(); // Hook to get current location

  // Function to extract token from URL query
  const getTokenFromURL = () => {
    const params = new URLSearchParams(location.search);
    return params.get("token"); // Extract the token
  };

  const [token, setToken] = useState("");

  useEffect(() => {
    const tokenFromURL = getTokenFromURL();
    if (tokenFromURL) {
      setToken(tokenFromURL);
    } else {
      toast.error("Invalid or missing token.");
      navigate("/login"); // Redirect to login if token is invalid
    }
  }, [location.search, navigate]);

  const validatePassword = () => {
    if (password.length < 8 || password.length > 15) {
      toast.error("Password must be between 8-15 characters.");
      return false;
    }
    if (!/[!@#$%^&*]/.test(password)) {
      toast.error("Password must include at least one special character.");
      return false;
    }
    if (password !== confirmPassword) {
      toast.error("Passwords do not match.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validatePassword()) return;

    try {
      // Call API to update the password
      await axios.post(
        `http://back.faithlandline.co.uk/api/auth/reset-password/${token}`,
        { newPassword: password }
      );

      toast.success("Password updated successfully. You can log in now.");
      navigate("/login"); // Redirect to login page
    } catch (error) {
      toast.error("Error updating password.");
    }
  };

  return (
    <div>
      {/* Breadcrumb Section */}
      <div className="breatcome-area align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="breatcome-content">
                <div className="breatcome-title">
                  <h1>Reset Password</h1>
                </div>
                <div className="breatcome-text">
                  <ul>
                    <li>
                      <Link to="/">
                        Home <i className="fa fa-angle-right"></i>
                      </Link>
                    </li>
                    <li>
                      <span>Reset Password</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Reset Password Form Section */}
      <div className="contact-area mt-12">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="contact-section">
                <div className="contact-title">
                  <h2>Reset Password</h2>
                </div>
                <div className="control-contact-form">
                  <form onSubmit={handleSubmit}>
                    {/* Password Input */}
                    <div className="input-box mb-4">
                      <label>New Password</label>
                      <input
                        type="password"
                        placeholder="Enter new password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    {/* Confirm Password Input */}
                    <div className="input-box mb-4">
                      <label>Confirm Password</label>
                      <input
                        type="password"
                        placeholder="Confirm new password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </div>
                    {/* Submit Button */}
                    <div className="input-button">
                      <button type="submit">Update Password</button>
                    </div>
                  </form>

                  <div className="col-md-8">
                    <p>
                      Don't have an account?{" "}
                      <Link to="/register">Register</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
