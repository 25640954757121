import React, { useState } from "react";
import { Link } from "react-router-dom";

const HeroSection = () => {
  // State to track which FAQ is open
  const [openFaqIndex, setOpenFaqIndex] = useState(null);

  // Function to toggle the FAQ
  const toggleFaq = (index) => {
    if (openFaqIndex === index) {
      setOpenFaqIndex(null); // Close if already open
    } else {
      setOpenFaqIndex(index); // Open the clicked one
    }
  };

  return (
    <div>
      {/* <!--breatcome-area Start--> */}
      <div className="breatcome-area align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="breatcome-content">
                <div className="breatcome-title">
                  <h1>Faq</h1>
                </div>
                <div className="breatcome-text">
                  <ul>
                    <li>
                      <Link to="/">
                        Home <i className="fa fa-angle-right"></i>
                      </Link>
                    </li>
                    <li>
                      <span>Faq</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- breatcome-area end--> */}

      {/* <!--faq-area-start--> */}
      <div className="faq-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="faq-area-left">
                <div className="section-title t-center">
                  <div className="section-sub-title">
                    <h5>
                      Have Any Questions? <span></span>
                    </h5>
                  </div>
                  <div className="section-main-title">
                    <h1>Frequently Asked Questions</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="faq-click-btn">
                <ul className="accordion">
                  <li>
                    <a href="#!" onClick={() => toggleFaq(0)}>
                      01. What does pro-rata/part month mean?
                    </a>
                    <p style={{ display: openFaqIndex === 0 ? "block" : "none" }}>
                      All tariffs run from the first of the month. When you sign
                      up, we only take a part payment so that you only pay for
                      the days you have used.
                    </p>
                  </li>
                  <li>
                    <a href="#!" onClick={() => toggleFaq(1)}>
                      02. Do the tariffs include outbound calls?
                    </a>
                    <p style={{ display: openFaqIndex === 1 ? "block" : "none" }}>
                      No, for this service you will need our mobile app called
                     FaithLandline Connect.
                    </p>
                  </li>
                  <li>
                    <a href="#!" onClick={() => toggleFaq(2)}>
                      03. What do you mean by diversion minutes?
                    </a>
                    <p style={{ display: openFaqIndex === 2 ? "block" : "none" }}>
                      When a customer calls your virtual number, you will be
                      using diversion minutes, this is us sending calls to your
                      mobile/landline.
                    </p>
                  </li>
                  <li>
                    <a href="#!" onClick={() => toggleFaq(3)}>
                      04. What does the charge inclusive minutes mean?
                    </a>
                    <p style={{ display: openFaqIndex === 3 ? "block" : "none" }}>
                      Once you have used up your inclusive minutes, you will be
                      charged the pence per minute rate for your tariff.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="faq-single-thumb faq-img-animation">
                <img src="assets/images/faq-bg.png" alt="faq img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--faq-area-end--> */}
    </div>
  );
};

export default HeroSection;
