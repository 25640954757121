import React from "react";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import { TiMessages } from "react-icons/ti";
import { GiRotaryPhone } from "react-icons/gi";
import { GiHeadphones } from "react-icons/gi";
import { BiTransfer } from "react-icons/bi";
import { MdOutlineAccountTree } from "react-icons/md";
import { TfiSignal } from "react-icons/tfi";
import "./MainPage.css";

const WhatsappBuisness = () => {
  return (
    <div>
      <div className="main-page">
        <div className="borderline w-full h-[1px]"></div>
        <section className="main-page__hero ">
          <div className="main-page__hero-content space-y-5">
            <h2 className="text-7xl font-sans font-semibold text-transparent bg-clip-text bg-gradient-to-t from-[#c6117d] to-[#e50471]">
              WhatsApp Business
            </h2>
            <h2 className="text-5xl font-sans font-thin">
              Use Your Virtual Number With WhatsApp
            </h2>
            <p className="text-2xl px-2 pt-6 font-sans font-thin">
              Whatsapp business allows small businesses to send two-way messages
              to your clients providing they too have the Whatsapp app. You can
              display your business phone number with Whatsapp Business.
            </p>
            <p className="text-2xl px-2 pb-4 font-sans font-thin">
              With over 2 billion users worldwide, offering a messaging service
              such as Whatsapp could be a great way for small businesses to
              connect with customers using a format clients already know and
              trust. Allowing potential clients to send you a WhatsApp message
              could be a way to generate new business from those who prefer not
              to call.
            </p>
            <div className="flex gap-6">
              <button className="main-page__cta-button font-sans font-semibold">
                Choose Your Number
              </button>
              <button className="main-page__cta-button font-sans font-semibold mx-2">
                Our Free Features
              </button>
            </div>
          </div>
          <div className=" w-[35%] flex justify-center items-center">
            <img
              src="https://www.tamartelecommunications.co.uk/wp-content/themes/tamar/img/free-shout.png"
              alt="Hero"
            />
          </div>
        </section>

        <div className="borderline w-full h-[1px]"></div>

        <div className="mt-4">
          <h1 className="text-3xl font-sans font-semibold text-transparent bg-clip-text bg-gradient-to-t from-[#c6117d] to-[#e50471]">
            What features does WhatsApp Business bring to my business?
          </h1>

          <ul className="list-disc space-y-2 pl-6 ">
            <li className="text-2xl font-sans font-thin">
              Create a branded profile with your business name and logo.
            </li>
            <li className="text-2xl font-sans font-thin">
              WhatsApp Business lets you message other WhatsApp users while
              displaying your business phone number and not your personal one.
            </li>
            <li className="text-2xl font-sans font-thin">
              You can label your conversations with customers to help stay
              organised. For example, “New Order” or “Support” etc.
            </li>
            <li className="text-2xl font-sans font-thin">
              WhatsApp lets you catalogue your products. This allows you to
              showcase and even purchase your products within the app.
            </li>
            <li className="text-2xl font-sans font-thin">
              Welcome customers by setting up a greeting that automatically
              responds when they send a message to your business WhatsApp.
            </li>
          </ul>
          <h1 className="text-3xl font-sans font-semibold text-transparent bg-clip-text bg-gradient-to-t from-[#c6117d] to-[#e50471]">
            Can I integrate my business number with Whatsapp Business?
          </h1>
          <p className="text-2xl font-sans font-thin">
            You can integrate your business phone number with Whatsapp Business.
            This allows you to display yourFaithLandline number when sending messages.
            Displaying yourFaithLandline number helps keep up that professional brand
            image when messaging your customers. Follow the steps below to setup
            your own Whatsapp Business Numbers in minutes.
          </p>
          <h1 className="text-3xl font-sans font-semibold text-transparent bg-clip-text bg-gradient-to-t from-[#c6117d] to-[#e50471]">
            What features does WhatsApp Business bring to my business?
          </h1>

          <ul className="list-decimal space-y-2 pl-6 ">
            <li className="text-2xl font-sans font-thin">
              Pick any number from our website.
            </li>
            <li className="text-2xl font-sans font-thin">
              We’ll get your new business number setup in 1 working hour.
            </li>
            <li className="text-2xl font-sans font-thin">
              Download and launch the WhatsApp Business app.
            </li>
            <li className="text-2xl font-sans font-thin">
              Follow their simple setup instructions. Once you are asked to
              complete registration with a 6 digit code, press send code then
              wait 30 seconds. An option will appear to enter code by phone,
              select that option and you will receive a call from your Tamar
              Business Number with the 6 digit code.
            </li>
            <li className="text-2xl font-sans font-thin">
              Finish setting up your WhatsApp account with your business name,
              logo and any of their business features you would like to add.
            </li>
          </ul>
          <p className="text-2xl font-sans font-thin">
            That’s it! YourFaithLandline Business number should now be working with
            your WhatsApp Business app.
          </p>
          <p className="text-2xl font-sans font-thin">
            For existing customers looking to add theirFaithLandline Number to Whatsapp
            Business, skip to step 3 above. Customers with Virtual Switchboards
            will need to remove this to complete setup, you can re-activate once
            setup is complete.
          </p>
          <p className="text-2xl font-sans font-thin">
            Important note: Whatsapp Business is a third-party app and we cannot
            offer support for any technical issues on their behalf.
          </p>
        </div>
        <div className="borderline w-full h-[1px]"></div>

        {/* <!-- Free Features Section --> */}
        <div class="container py-5">
          <div class="text-center mb-5">
            <h1 class="display-4 fw-semibold text-gradient">Free Features</h1>
            <p class="fs-4 text-muted">
              The below features are included with all telephone numbers
            </p>
          </div>
          <div class="row mb-5">
            <div class="col-12">
              <p class="fs-3 d-flex flex-wrap justify-content-center gap-4">
                <a href="#" class="text-decoration-none link-pink">
                  Online Number Management
                </a>
                <a href="#" class="text-decoration-none link-pink">
                  Welcome Announcement
                </a>
                <a href="#" class="text-decoration-none link-pink">
                  Hunt Groups
                </a>
                <a href="#" class="text-decoration-none link-pink">
                  Call Statistics
                </a>
                <a href="#" class="text-decoration-none link-pink">
                  Timed Diverts
                </a>
                <a href="#" class="text-decoration-none link-pink">
                  Voicemail
                </a>
                <a href="#" class="text-decoration-none link-pink">
                  Missed Call Alerts
                </a>
                <a href="#" class="text-decoration-none link-pink">
                  Blacklist
                </a>
                <a href="#" class="text-decoration-none link-pink">
                  Mobile App
                </a>
                <a href="#" class="text-decoration-none link-pink">
                  Anonymous Call Rejection
                </a>
                <a href="#" class="text-decoration-none link-pink">
                  Holiday Settings
                </a>
                <a href="#" class="text-decoration-none link-pink">
                  WhatsApp Business
                </a>
                <a href="#" class="text-decoration-none link-pink">
                  Number Porting
                </a>
                <a href="#" class="text-decoration-none link-pink">
                  Fax To Email
                </a>
                <a href="#" class="text-decoration-none link-pink">
                  International Call Blocking
                </a>
              </p>
            </div>
          </div>

          {/* <!-- Feature Description Section --> */}
          <section class="d-flex align-items-center shadow-lg p-5 rounded">
            <img
              src="https://www.tamartelecommunications.co.uk/wp-content/themes/tamar/img/hero-lady.webp"
              alt="Hero"
              class="img-fluid rounded me-5"
              style={{ width: "18rem" }}
            />
            <div>
              <h1 class="display-5 fw-semibold">Online Number Management</h1>
              <p class="fs-5 text-muted">
                The online number management control panel allows changes to be
                made to numbers instantly 24/7. The control panel allows you to
                modify numbers with ease, as well as for viewing call statistic
                reports, all at a click of a button.
              </p>
              <a href="#" class="btn btn-outline-danger rounded-pill px-4 py-2">
                Find Out More
              </a>
            </div>
          </section>
        </div>

        <div className="borderline w-full mt-2 h-[1px]"></div>

        {/* <!-- Premium Features Section --> */}
        <div class="container py-5">
          <div class="text-center mb-5">
            <h1 class="text-gradient">Our Premium Features</h1>
            <p class="fs-4 text-muted">
              Some of our other premium features available to help your business
            </p>
          </div>
          <div class="row g-4">
            <div class="col-md-3">
              <div class="card text-center shadow-lg border-pink">
                <div class="card-body">
                  <i class="bi bi-diagram-2-fill text-danger display-1 mb-4"></i>
                  <h3 class="card-title">Virtual Switchboard</h3>
                  <p class="card-text">
                    Your virtual phone system with options
                  </p>
                  <a href="#" class="btn btn-outline-danger rounded-pill">
                    Find Out More
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card text-center shadow-lg border-pink">
                <div class="card-body">
                  <i class="bi bi-arrows-move text-danger display-1 mb-4"></i>
                  <h3 class="card-title">Call Whisper</h3>
                  <p class="card-text">
                    Your virtual phone system with options
                  </p>
                  <a href="#" class="btn btn-outline-danger rounded-pill">
                    Find Out More
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card text-center shadow-lg border-pink">
                <div class="card-body">
                  <i class="bi bi-telephone-forward text-danger display-1 mb-4"></i>
                  <h3 class="card-title">Outbound Calling</h3>
                  <p class="card-text">
                    Make outbound calls using your virtual numbers
                  </p>
                  <a href="#" class="btn btn-outline-danger rounded-pill">
                    Find Out More
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card text-center shadow-lg border-pink">
                <div class="card-body">
                  <i class="bi bi-headset text-danger display-1 mb-4"></i>
                  <h3 class="card-title">Tamar Voice AI</h3>
                  <p class="card-text">
                    Instantly generate telephone AI greetings
                  </p>
                  <a href="#" class="btn btn-outline-danger rounded-pill">
                    Find Out More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Get in Touch Section --> */}
        <div class="bg-gradient-to-top text-white py-5">
          <div class="container text-center mb-4">
            <h1 class="mb-4">Get In Touch...</h1>
          </div>
          <div class="container">
            <div class="row text-center">
              <div class="col-md-4">
                <i class="bi bi-envelope display-1"></i>
                <p class="fs-4">hello@tamar.co.uk</p>
              </div>
              <div class="col-md-4">
                <i class="bi bi-telephone-fill display-1"></i>
                <p class="fs-4">0800 772 0000</p>
              </div>
              <div class="col-md-4">
                <i class="bi bi-headset display-1"></i>
                <p class="fs-4">Contact Page</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatsappBuisness;
