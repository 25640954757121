import React from "react";
import StatsComp from "../../component/ControlPanel/StatsComp";

const StatsCompPage = () => {
  return (
    <div>
      <StatsComp />
    </div>
  );
};

export default StatsCompPage;
